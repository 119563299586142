<template>
  <div>
    <el-form :model="searchForm" ref="searchForm" label-width="70px" class="searchForm">

      <el-row>
        <el-col :span='6'>
          <el-form-item label="部门">
            <selects @handleChange="handleSelect($event,0)" ref="departmentRef"
              :options="department">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="职位">
            <selects @handleChange="handleSelect($event,1)" ref="rolesCodeRef" :options="rolesCode">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="用户名">
            <selects @handleChange="handleSelect($event,2)" ref="userNameRef" :options="proManager">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-button class="formBtn" @click="resetForm">重置</el-button>
          <el-button class="formBtn searchBtn" :loading="Searching" @click="search">搜索</el-button>
          <el-button class="formBtn searchBtn" @click="createUser(id=-1)">新增用户
          </el-button>
        </el-col>
      </el-row>

    </el-form>

    <!-- 用户列表 -->
    <div>
      <el-table :data="userList" border stripe v-loading="Searching" :row-style="{height:'64px',}"
        :cell-style="{'text-align':'center'}"
        :header-cell-style="{'border-top':'solid 1px #E3E3E3','background': '#F5F5F5','text-align':'center'}"
        style="width: 100%">
        <el-table-column prop="id" label="ID" min-width="70">
        </el-table-column>
        <el-table-column prop="userName" label="名称" min-width="150">
        </el-table-column>
        <el-table-column prop="jobTitle" label="职位" min-width="150">
        </el-table-column>
        <el-table-column prop="department" label="部门" min-width="150">
        </el-table-column>
        <el-table-column prop="memberType" label="人员类型" min-width="150">
          <template slot-scope="scope">
            <span style="color: #409EFF;">{{scope.row.memberType==0 ? '未审核' : ''}}</span>
            <span style="color: #409EFF;">{{scope.row.memberType==1 ? '内部人员' : ''}}</span>
            <span style="color: #409EFF;">{{scope.row.memberType==2 ? '外包人员' : ''}}</span>
            <el-tooltip :content="scope.row.reason" placement="bottom">
              <span style="color: #409EFF;">{{scope.row.memberType==3 ? '审核拒绝' : ''}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="电话" min-width="150">
        </el-table-column>
        <el-table-column prop="email" label="邮箱" min-width="240">
        </el-table-column>
        <el-table-column label="操作" fixed="right" min-width="220">
          <template slot-scope="scope">
            <el-button type="text" @click="openTransfer(scope.row.id,scope.row.userName)">项目管理
            </el-button>
            <el-button type="text" @click="delectUser(scope.row.id)">删除</el-button>
            <el-button type="text" v-if="scope.row.memberType==0" @click="openReview(scope.row.id)">
              审核成员</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <Transfer ref="Transfer"></Transfer>

    <addUser ref="addUser" @addUserSuccess="search" :datedepartment="department"
      :daterolesCode="rolesCode"></addUser>

    <el-dialog title="审核" :visible.sync="isreView" width="400px" :before-close="closeReview"
      :close-on-click-modal='false'>

      <el-form :model="reViews" :rules="reViewsRules" ref="reViewsForm" label-width="80px">
        <el-row>
          <el-form-item label="人员类型" prop="memberType">
            <selects @handleChange="handleSelect($event,3)" ref="memberTypeRef"
              :options="memberType">
            </selects>
          </el-form-item>
        </el-row>
        <el-row v-if="reViews.memberType==3">
          <el-form-item label="原因" prop="reason">
            <el-input type="textarea" placeholder="拒绝原因说明" rows="4" v-model="reViews.reason"
              maxlength="100" show-word-limit>
            </el-input>
          </el-form-item>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeReview">取 消</el-button>
        <el-button type="primary" @click="reviewUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 分页器 -->
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="1" :page-sizes="[5, 10, 15]" :page-size="100"
        layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { POST_QUERYMENBER, POST_AUDITMEMBER } from "../request/api";
import { selects, Transfer, addUser } from "./parts";
export default {
  data() {
    return {
      dataTotal: 0,
      Searching: false,
      isreView: false,
      searchForm: {
        department: "", //所属部门
        rolesCode: null, //所在岗位
        userName: "", //名称

        pageNumber: 1,
        pageSize: 5,
      },

      // 审核
      reViews: {
        memberId: null,
        memberType: null,
        status: null,
        reason: "",
      },
      reViewsRules: {
        memberType: [
          { required: true, message: "请选择人员类型", trigger: "blur" },
        ],
        reason: [
          { required: true, message: "请输入拒绝原因", trigger: "blur" },
        ],
      },

      //   部门
      department: [{ value: "合鸣科技研发中心", label: "合鸣科技研发中心" }],
      //   职位
      rolesCode: [ 
        { value: 200, label: "客户" },
        { value: 100, label: "部门经理" },
        { value: 101, label: "UI" },
        { value: 102, label: "前端开发" },
        { value: 103, label: "后端开发" },
        { value: 104, label: "开发经理" },
        { value: 105, label: "测试" },
        { value: 106, label: "测试经理" },
      ],
      // 人员类型
      memberType: [
        { value: 1, label: "内部员工" },
        { value: 2, label: "外包员工" },
        { value: 3, label: "审核拒绝" },
      ],
      // 人员
      proManager: [],
      userList: [],
    };
  },
  created() {
    this.search();
  },
  methods: {
    getMember() {
      this.$util.getMember();
      this.proManager = this.$store.state.Member;
    },
    handleSelect(val, type) {
      if (type == 0) {
        this.searchForm.department = val;
      } else if (type == 1) {
        this.searchForm.rolesCode = val;
      } else if (type == 2) {
        this.searchForm.userName = val;
      } else {
        this.reViews.memberType = val;
      }
    },
    //重置搜索条件
    resetForm() {
      this.searchForm.department = "";
      this.searchForm.rolesCode = null;
      this.searchForm.userName = "";
      const refList = ["departmentRef", "rolesCodeRef", "userNameRef"];
      refList.forEach((element) => {
        this.$refs[element].resetValue();
      });
      this.search();
    },
    //搜索
    search() {
      this.getMember();
      this.Searching = true;
      const waitsearch = setTimeout(() => {
        this.Searching = false;
      }, 600);
      POST_QUERYMENBER(this.searchForm)
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.dataTotal = res.data.total;
            this.userList = res.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      waitsearch;
    },
    // 打开项目管理
    openTransfer(id, name) {
      this.$refs.Transfer.open(id, name);
    },
    //打开审核
    openReview(id) {
      this.reViews.memberId = id;
      this.isreView = true;
    },
    // 确认审核
    reviewUser() {
      this.$refs.reViewsForm.validate((valid) => {
        if (valid) {
          this.auditUser();
        }
      });
    },
    // 关闭审核
    closeReview() {
      if (this.reViews.memberType) {
        this.$refs.memberTypeRef.resetValue();
      }
      this.reViews.reason = "";
      this.reViews.memberId = null;
      this.reViews.memberType = null;
      this.reViews.status = null;
      this.isreView = false;
    },
    // 新增用户
    createUser(id) {
      this.$refs.addUser.openNewUser(id);
    },
    // 操作用户
    auditUser() {
      POST_AUDITMEMBER(this.reViews)
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.$notify.success({
              title: "成功",
              message: "操作成功",
            });
            this.search();
          } else {
            this.$notify.error({
              title: "错误",
              message: "审核失败请重试",
            });
          }
          this.closeReview();
          this.getMember();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除用户
    delectUser(id) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.reViews.memberId = id;
          this.reViews.status = 1;
          this.auditUser();
          // console.log("删除成功");
        })
        .catch(() => {});
    },

    // 分页器
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.searchForm.pageNumber = val;
      this.search();
    },
  },
  components: {
    selects,
    Transfer,
    addUser,
  },
};
</script>

<style lang="less" scoped>
// 搜索按钮
.formBtn {
  height: 36px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 36px;
  padding: 0;
  border-radius: 0;
  padding: 0 22px;
}
.searchBtn {
  color: #fff;
  background: #3285ea;
}
</style>